<template>
  <div v-loading="pageLoading">
    <div class="card mb-5 mb-xl-10" v-if="formData.master_package_id">
      <div class="card-header border-0 pt-6">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="text-gray-900 fs-2 fw-bolder me-1">{{ oldPackageData.name }}</span>
          </div>
          <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
            <span class="d-flex align-items-center text-gray-400 me-5 mb-2" v-if="oldPackageData.description == 0">{{ packageConstants.INTERNAL }}</span>
            <span class="d-flex align-items-center text-gray-400 me-5 mb-2" v-if="oldPackageData.description == 1">{{ packageConstants.EXTERNAL }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-0 pt-6">
        <el-form @submit.prevent="submit()" :model="formData" ref="formRef" class="w-100">
          <div class="row g-9">
            <div class="col-md-3 fv-row">
              <div class="mb-6">
                <label class="fs-6 fw-bold mb-2">{{ packageConstants.PACKAGE_TYPE }}</label>
                  <el-form-item prop="package_type">
                    <el-radio-group v-model="formData.package_type">
                      <el-radio :label="0" > {{ packageConstants.INTERNAL }} </el-radio>
                      <el-radio :label="1"> {{ packageConstants.EXTERNAL }} </el-radio>
                    </el-radio-group>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-3 fv-row">
              <div class="mb-6">
                <label class="required fs-6 fw-bold mb-2">{{ packageConstants.PACKAGE_NAME }}</label>
                <el-form-item prop="package_name"
                :rules="{
                  required: true,
                  message: packageConstants.PACKAGE_NAME_REQUIRED,
                  trigger: 'blur'
                }">
                  <el-input size="large" v-model="formData.package_name" type="text" @keyup="generatePackageCode(formData.package_name)" :placeholder="packageConstants.PACKAGE_NAME"/>
                </el-form-item>
              </div>
            </div>
            <div class="col-md-3 fv-row">
              <div class="mb-6">
                <label class="required fs-6 fw-bold mb-2">{{ packageConstants.PACKAGE_CODE }}</label>
                <el-form-item prop="package_code"
                :rules="{
                  required: true,
                  validator: checkUniquePackage,
                  trigger: 'blur'
                }">
                  <el-input size="large" v-model="formData.package_code" type="text" :placeholder="packageConstants.PACKAGE_CODE"/>
                </el-form-item>
              </div>
            </div>
            <div class="col-md-3 fv-row">
              <div class="mb-6">
                <label class="fs-6 fw-bold mb-2">{{ packageConstants.WEIGHT_UNIT }}</label>
                <el-form-item prop="weight_class">
                  <el-select v-model="formData.weight_class" :placeholder="packageConstants.CHOOSE_WEIGHT" size="large" class="w-100">
                    <el-option :label="weightval" :value="weightkey" :key="weightkey"  v-for="(weightval, weightkey) in weightClass.value"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="col-md-3 fv-row">
              <div class="mb-6">
                <label class="fs-6 fw-bold mb-2">{{ packageConstants.LENGTH_UNIT }}</label>
                <el-form-item prop="length_class">
                  <el-select v-model="formData.length_class" :placeholder="packageConstants.CHOOSE_LENGTH" size="large" class="w-100">
                    <el-option :label="lengthval" :value="lengthkey" :key="lengthkey"  v-for="(lengthval, lengthkey) in lengthClass.value"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-2 fv-row">
              <div class="mb-6">
                <label class="required fs-6 fw-bold mb-2">{{ packageConstants.MAX_WEIGHT }}</label>
                <el-form-item prop="max_weight" 
                :rules="{
                  required: true,
                  validator: checkNumber,
                  requiredmessage: packageConstants.MAX_WEIGHT_REQUIRED,
                  trigger: 'change'
                }">
                  <el-input size="large" v-model="formData.max_weight" type="text" :placeholder="packageConstants.MAX_WEIGHT" />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-2 fv-row">
              <div class="mb-6">
                <label class="required fs-6 fw-bold mb-2">{{ packageConstants.BOX_WEIGHT }}</label>
                <el-form-item prop="box_weight" 
                :rules="{
                  required: true,
                  validator: checkNumber,
                  requiredmessage: packageConstants.BOX_WEIGHT_REQUIRED,
                  trigger: 'change'
                }">
                  <el-input size="large" v-model="formData.box_weight" type="text" :placeholder="packageConstants.BOX_WEIGHT" />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-2 fv-row">
              <div class="mb-6">
                <label class="required fs-6 fw-bold mb-2">{{ packageConstants.LENGTH }}</label>
                <el-form-item prop="package_length" 
                :rules="{
                  required: true,
                  validator: checkNumber,
                  requiredmessage: packageConstants.LENGTH_REQUIRED,
                  trigger: 'change'
                }">
                  <el-input size="large" v-model="formData.package_length" type="text" :placeholder="packageConstants.LENGTH" />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-2 fv-row">
              <div class="mb-6">
                <label class="required fs-6 fw-bold mb-2">{{ generalConstants.HEIGHT }}</label>
                <el-form-item prop="package_height" 
                :rules="{
                  required: true,
                  validator: checkNumber,
                  requiredmessage: packageConstants.HEIGHT_REQUIRED,
                  trigger: 'change'
                }">
                  <el-input size="large" v-model="formData.package_height" type="text" :placeholder="generalConstants.HEIGHT" />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-2 fv-row">
              <div class="mb-6">
                <label class="required fs-6 fw-bold mb-2">{{ generalConstants.WIDTH }}</label>
                <el-form-item prop="package_width" 
                :rules="{
                  required: true,
                  validator: checkNumber,
                  requiredmessage: packageConstants.WIDTH_REQUIRED,
                  trigger: 'change'
                }">
                  <el-input size="large" v-model="formData.package_width" type="text" :placeholder="generalConstants.WIDTH" />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-2 fv-row">
              <div class="mb-6">
                <label class="fs-6 fw-bold mb-2">{{ generalConstants.STATUS }}</label>
                <el-form-item prop="status">
                  <el-switch v-model="formData.status" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-12 mb-5 mt-12">
                <button type="button" class=" btn btn-primary me-2" v-if="((permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed))" :disabled="loading" @click="submitForm(formRef, 'save')">{{generalConstants.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                <button type="button" class=" btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{generalConstants.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                <button class="btn btn-secondary me-1" :disabled="disabled" @click.prevent="$router.push('/master-packages')">{{ generalConstants.CANCEL }}</button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, onUpdated } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { checkNumber } from "@/composable/common-validation.js";
import { useRoute } from "vue-router";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import useEventBus from '../../composable/useEventBus'
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";

export default {
  name: "add-master-package",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const route = useRoute();
    const router = useRouter();
    const formRef = ref(null);
    const loading = ref(false);
    const loadingback = ref(false);
    const disabled = ref(false);
    const weightClass = reactive({});
    const lengthClass = reactive({});
    const pageLoading = ref(false);
    const generalConstants = globalConstant.general;
    const packageConstants = globalConstant.package;
    const oldPackageData = reactive({
      name: '',
      description: ''
    });
    const formData = reactive({
      master_package_id: 0,
      package_type: 0,
      package_name: "",
      package_code: "",
      weight_class: "LBS",
      max_weight: "",
      box_weight: "",
      length_class: "IN",
      package_length: "",
      package_width: "",
      package_height: "",
      status: true
    });
    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "save"){
        submitForm(formRef, 'save', true)
      }else if(actionName == "save_back"){
        submitForm(formRef, 'save_back',true)
      }else if(actionName == "cancel"){
        router.push('/master-packages');
      }
    })
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
    })
    //Validate Package name
    const checkUniquePackage = (rule, value, callback) => {
      if(!value) {
        callback(new Error(packageConstants.PACKAGE_CODE_REQUIRED));
      } else if(/\s/g.test(value)) {
        callback(new Error('Spaces are not allowed'));
      } else if(value) {
        ApiService.query("check-unique-master-package", {
          params: {
            package_code: value,
            package_type: formData.package_type,
            master_package_id: formData.master_package_id
          }
        })
        .then(({ data }) => {
          if(data.data > 0) {
            callback(new Error(packageConstants.UNIQUE_PACKAGE_REQUIRED));
          } else {
            callback()
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            if (status == 404) {
              router.push({ name: "add-master-package" });
            }
          } 
        });
      } else {
        callback()
      }
    }

    const generatePackageCode = (packageName) => {
      formData.package_code = packageName.replace(/\s/g, '');
    };

    //Submit product master package data
    const submitForm = async (formEl, btnName, isToolbarButtonsSubmitAction = false) => {
      formEl = (isToolbarButtonsSubmitAction)?formEl.value:formEl;
      if (!formEl) {
        return;
      }
      formEl.validate((valid) => {
        if (!valid) return false;
         
        (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":true,"action":btnName}) : (btnName == "save")?(loading.value = true) : (loadingback.value = true);

        let addParams = {
          _method: formData.master_package_id > 0 ? "PUT" : null,
          package_type: formData.package_type,
          package_name: formData.package_name,
          package_code: formData.package_code,
          weight_class: formData.weight_class,
          max_weight: formData.max_weight,
          box_weight: formData.box_weight,
          length_class: formData.length_class,
          package_length: formData.package_length,
          package_width: formData.package_width,
          package_height: formData.package_height,
          status: formData.status,
        };
        let apiURL = "master-packages";
        if(formData.master_package_id > 0) {
          apiURL = "master-packages/" + formData.master_package_id;
        }
        ApiService.post(apiURL, addParams)
        .then(({ data }) => {
          if(data) {              
            if (formData.master_package_id == 0 || formData.master_package_id === undefined)
              formData.master_package_id = data.data.master_package_id;
            oldPackageData.name = formData.package_name;
            oldPackageData.description = formData.package_type;
            notificationFire(data.message, generalConstants.NOTIFY_SUCCESS)
            if (btnName == 'save') {
              (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);

              router.push({ name: 'edit-master-package', params: { id: formData.master_package_id } })
            } else if (btnName == 'save_back') {
              router.push({ name: 'master-packages'});
            }
          }
        })
        .catch((error) => {
            (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
            let message = '';
            if (typeof (error.response) !== 'undefined') {
              message = error.response.data.message;
            } else if (typeof (error.message) !== 'undefined') {
              message = error.message;
            } else {
              message = generalConstants.NOTIFY_WRONG_MESSAGE;
            }
            notificationFire(message, generalConstants.NOTIFY_ERROR);
          });
      });
    }

    //Get product master package data
    const getProductMasterPackageData = async() => {
      pageLoading.value = true;
      await ApiService.query("master-packages/" + formData.master_package_id)
      .then(({ data }) => {
        if (data.data) {
          let MPackageData = data.data[0];
          formData.package_type = oldPackageData.description = Number(MPackageData.package_type);
          formData.package_name = oldPackageData.name = MPackageData.package_name;
          formData.package_code = MPackageData.package_code;
          formData.weight_class = MPackageData.weight_class;
          formData.max_weight = MPackageData.max_weight;
          formData.box_weight = MPackageData.box_weight;
          formData.length_class = MPackageData.length_class;
          formData.package_length = MPackageData.package_length;
          formData.package_width = MPackageData.package_width;
          formData.package_height = MPackageData.package_height;
          formData.status = (MPackageData.status == 1) ? true : false;
          pageLoading.value = false;
        }
      })
      .catch((error) => {
        pageLoading.value = false;
        let message = '';
        if (typeof (error.response) !== 'undefined') {
          message = error.response.data.message;
        } else if (typeof (error.message) !== 'undefined') {
          message = error.message;
        } else {
          message = generalConstants.NOTIFY_WRONG_MESSAGE;
        }
        notificationFire(message, generalConstants.NOTIFY_ERROR);
      });
    }

    // Get Weight Unit
    const getWeightClass = async() => {
      await ApiService.query("get-weight-class")
      .then(({ data }) => {
        weightClass.value = data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let status = response.status;
          if (status == 404) {
            router.push({ name: "add-master-package" });
          }
        } 
      });
    }

    // Get Length Unit
    const getLengthClass = async() => {
      await ApiService.query("get-length-class")
      .then(({ data }) => {
        lengthClass.value = data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let status = response.status;
          if (status == 404) {
            router.push({ name: "add-master-package" });
          }
        } 
      });
    }

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name" :generalConstants.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader':false, 'isAllowed': (permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed)}, {"name": generalConstants.SAVEANDBACK , 'action': 'save_back','class': 'btn-primary me-3','isLoader':false }, {"name": generalConstants.CANCEL , 'action': 'cancel','class': 'btn-secondary','isLoader':false }]);
    }

    //Set breadcrumbs
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": "Master Packages", "path": "master-packages"}, { name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createMasterPackage") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateMasterPackage") || role == "Super Admin")
        permission.isEditAllowed = true;
        
      getWeightClass();
      getLengthClass();
      formData.master_package_id = router.currentRoute.value.params.id;       
      if(formData.master_package_id > 0 ) {
        await getProductMasterPackageData();
      }
      generateHeaderToolbarButtons();
    })
    onUpdated(async () => {
      generateHeaderToolbarButtons();
    })
    
    return {
      router,
      formRef,
      loading,
      loadingback,
      formData,
      submitForm,
      getProductMasterPackageData,
      disabled,
      checkNumber,
      getWeightClass,
      weightClass,
      getLengthClass,
      lengthClass,
      pageLoading,
      checkUniquePackage,
      generalConstants,
      packageConstants,
      permission,
      oldPackageData,
      generatePackageCode
    };
  },
};

</script>